// import { toast } from "react-toastify";
import {WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ALL_ITEMS, WISHLIST_REMOVE_ITEM} from "./wishlistActionTypes";

export function wishlistAddItemSuccess(product, locale) {

    return {
        type: WISHLIST_ADD_ITEM,
        product,
    };
}

export function wishlistRemoveItemSuccess(productId) {
    return {
        type: WISHLIST_REMOVE_ITEM,
        productId,
    };
}

export function wishlistAddItem(product, locale) {
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(wishlistAddItemSuccess(product, locale));
                resolve();
            }, 500);
        });
}

export function wishlistRemoveItem(productId) {
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(wishlistRemoveItemSuccess(productId));
                resolve();
            }, 500);
        });
}

export function wishListRemoveAllItems() {
    return { type: WISHLIST_REMOVE_ALL_ITEMS };
}
